@use '../_global/typography';

h1,
.h1 {
  @include typography.headline-one(var(--default-font));
}

h2,
.h2 {
  @include typography.headline-two(var(--default-font));
}

h3,
.h3 {
  @include typography.headline-three(var(--default-font));
}

h4,
.h4 {
  @include typography.headline-four(var(--default-font));
}

input,
p,
a,
.p,
.a {
  @include typography.copy-txt(var(--default-font));

  &.bold {
    @include typography.copy-txt-bold(var(--default-font));
  }

  &.small {
    @include typography.copy-txt-small(var(--default-font));
  }
}

span,
.span {
  @include typography.span-txt(inherit);
}

i,
.i {
  @include typography.copy-txt(unset);
}

button,
.button {
  @include typography.button-txt(var(--default-font));
}
