@use 'scaling' as *;
@use 'libs/shared/util-styles/src/mixins';

:root {
  --header-height: #{base(7)};
  --sub-header-height: #{base(6)};

  --sidebar-collapsed-width: #{base(6)};
  --cosmos-sidebar-width: #{base(40)};

  --quickdraw-profile-width: #{base(50)};

  @include mixins.mq(desktop-s-width) {
    --quickdraw-profile-width: #{base(37.5)};
  }

  @include mixins.mq(desktop-xxs-width) {
    --quickdraw-profile-width: #{calc(100vw - var(--sidebar-collapsed-width))};
  }
}
